import { useFormikContext } from 'formik'
import Link from 'next/link'

import styles from './Button.module.css'

const Button = (props) => {
    const { isSubmitting: loading, isValid, dirty } = useFormikContext() || {}

    const disabled = props.disabled || (dirty && !isValid)

    return (
        <button
            className={`btn ${styles.btn} ${
                props.modification ? props.modification : 'px-5 w-auto'
            } ${styles[props.size]} ${styles[props.variant]} ${
                disabled ? styles.disabled : ''
            } ${loading ? styles.loading : ''}`}
            disabled={disabled}
            type={props.type ? props.type : 'submit'}
            onClick={props.click}
            id={props.id}
        >
            <span
                className={`flex items-center justify-center icon-position ${
                    styles.iconPosition
                } ${styles[props.position]}`}
            >
                {props.icon && <i className={`icon-` + props.icon}></i>}
                <span className={`${styles.labelText} ${props.labelColor}`}>
                    {props.text}
                </span>
            </span>
            <span className={styles.spinner}>
                <svg
                    className="w-full h-auto m-auto text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </span>
            {props.link && (
                <Link href={props.link}>
                    <a className="absolute top-0 left-0 right-0 z-40 w-full h-full"></a>
                </Link>
            )}
        </button>
    )
}

export default Button
