import styles from './Main.module.css'

import Header from '../components/base/Header'
import Footer from '../components/base/Footer'

const Main = (props) => {
    return (
        <main>
            <div
                itemScope
                itemType="https://schema.org/WebPage"
                className={styles.MainLayout}
            >
                {/* Header */}
                <Header />

                <div>{props.children}</div>

                {/* Footer */}
                <Footer />
            </div>
        </main>
    )
}

export default Main
