import { Modal, ModalBody } from '@windmill/react-ui'
import Image from 'next/image'

import Subscribe from '../../components/Subscribe'

const JoinWaitlist = ({ isModalOpen, closeModal }) => {
    return (
        <>
            <div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    className="mx-auto my-auto bg-white rounded-3xl w-[640px] modalHeader mobile:max-w-[92%]"
                >
                    <div className="flex flex-col items-center justify-center mt-8">
                        <ModalBody>
                            <div className="w-[215px] h-[215px] mb-4 mx-auto">
                                <Image
                                    src="/svg/join-waitlist.svg"
                                    alt="join-waitlist"
                                    layout="responsive"
                                    width={215}
                                    height={215}
                                />
                            </div>
                            <div className="px-12 mb-8 text-center mobile:px-6">
                                <div className="mb-8">
                                    <h1 className="mb-8 text-primary-900">
                                        Join the waitlist
                                    </h1>
                                    <p className="text-base font-normal text-interface-900">
                                        We&apos;ve created a waitlist to manage
                                        demand in a fair and efficient way.{' '}
                                        Please join our waitlist and we&apos;ll
                                        email you as soon as we&apos;re ready to
                                        welcome you onboard.
                                    </p>
                                </div>
                                <Subscribe signup={true} />
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default JoinWaitlist
