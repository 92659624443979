const navigationItems = [
    {
        id: 1,
        label: 'Solutions',
        route: '#',
        children: [
            {
                id: 1,
                label: 'Ecommerce',
                description:
                    'Improve sales by offering personalized recommendations, and more',
                image: '/landing/icons/ecommerce.svg',
                route: '/machine-learning-solutions/ecommerce',
            },
            {
                id: 2,
                label: 'Social Media & Communication',
                description:
                    'Get insight on public perception across your social channels',
                image: '/landing/icons/social-media.svg',
                route: '/machine-learning-solutions/social-media-communication',
            },
            {
                id: 3,
                label: 'Digital Marketing',
                description:
                    'Find out which of your digital marketing campaigns are ineffective and why',
                image: '/landing/icons/digital-marketing.svg',
                route: '/machine-learning-solutions/digital-marketing',
            },
            {
                id: 4,
                label: 'Enterprise',
                description:
                    'Generate actionable insight across the enterprise in Sales, HR, Finance & more',
                image: '/landing/icons/enterprise.svg',
                route: '/machine-learning-solutions/enterprise',
            },
            {
                id: 5,
                label: 'Real Estate',
                description:
                    'Use AI to evaluate property prices in relation to the current market',
                image: '/landing/icons/real-estate.svg',
                route: '/machine-learning-solutions/real-estate',
            },
            {
                id: 6,
                label: 'Restaurants',
                description:
                    "Find out how to best leverage your restaurant's data to increase revenue",
                image: '/landing/icons/resturant.svg',
                route: '/machine-learning-solutions/restaurant',
            },
        ],
    },
    {
        id: 2,
        label: 'Company',
        route: '/company',
    },
    {
        id: 3,
        label: 'Insights',
        route: '/insights',
    },
    {
        id: 4,
        label: 'Contact Us',
        route: '/contact',
        footer: true,
    },
    /*  {
        id: 5,
        label: 'Login',
        route: '/',
        footer: true,
    },*/
    {
        id: 6,
        label: 'Try Smart Answers',
        route: '#',
        footer: true,
        hasClickHandler: true,
    },
]

export default navigationItems
