import React, { useState } from 'react'
import JoinWaitlist from '../modals/JoinWaitlist'
import Button from './Button'

const JoinWaitList = (props) => {
    // Join Waitlist Modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
    }
    return (
        <div>
            <Button
                text={
                    props.btnLabel
                        ? props.btnLabel
                        : 'Join early access waitlist'
                }
                type="button"
                variant={props.variant ? props.variant : 'primary'}
                modification="tablet:w-full tablet:h-12 tablet:text-base px-4"
                size={props.size ? props.size : ''}
                click={openModal}
            />
            {/* Join Waitlist Modal */}
            <JoinWaitlist isModalOpen={isModalOpen} closeModal={closeModal} />
        </div>
    )
}

export default JoinWaitList
