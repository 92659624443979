import React, { useState, useEffect } from 'react'

import ScrollArea from 'react-perfect-scrollbar'
import { useMediaQuery } from 'react-responsive'

import Logo from '../Logo'
import Navigation from './Navigation'
import Button from './Button'
import JoinWaitListBtn from './JoinWaitListBtn'

import { ClientOnly } from 'react-client-only'

import styles from './Header.module.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

const Header = () => {
    const [scroll, setScroll] = useState(false)
    const [mobileClass, setMobileClass] = useState(false)
    const handleScroll = function () {
        setScroll(window.scrollY > 80)
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })
    // Mobile Menu
    const mobileMenuHandler = () => {
        setMobileClass(!mobileClass)
    }
    // For desktop only
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)',
    })
    // For mobile only
    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 767px)',
    })

    return (
        <div className={styles.header}>
            <div
                className={`${styles.inner} $ ${
                    scroll ? styles['headerShadow'] : ''
                }`}
            >
                <div className="container flex items-center ">
                    {/* Logo */}
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                    {/* Navigation */}
                    <div
                        className={` ${styles.navigation} ${
                            mobileClass ? styles.showMenu : ''
                        }`}
                    >
                        <div>
                            {/* For Desktop Only*/}
                            <ClientOnly>
                                {isDesktopOrLaptop && (
                                    <Navigation navigationType="headerNavigation" />
                                )}
                            </ClientOnly>
                        </div>
                        {/* For Mobile Only*/}
                        <div>
                            <ClientOnly>
                                <ScrollArea>
                                    <div className={styles.mobileScroll}>
                                        {isTabletOrMobile && (
                                            <Navigation
                                                hideMobileMenu={
                                                    mobileMenuHandler
                                                }
                                                navigationType="headerNavigation"
                                            />
                                        )}
                                    </div>
                                </ScrollArea>
                            </ClientOnly>
                        </div>
                        <div className="flex gap-6 pl-4 tablet:w-full tablet:px-5 tablet:border-b tablet:border-interface-200 tablet:mb-4 tablet:pb-3">
                            <div className="tablet:w-2/4">
                                <JoinWaitListBtn btnLabel="Join waitlist" />
                            </div>
                            <div className="hidden tablet:w-2/4">
                                <Button
                                    text="Try for free"
                                    type="button"
                                    variant="primary"
                                    modification="tablet:w-full tablet:h-12 tablet:text-base px-4"
                                    link="#"
                                />
                            </div>
                            <div className="ipad:hidden tablet:w-2/4 tablet:block">
                                <Button
                                    text="Contact Us"
                                    type="button"
                                    variant="outline"
                                    modification="tablet:w-full tablet:h-12 tablet:text-base px-4"
                                    link="/contact"
                                />
                            </div>
                        </div>
                    </div>
                    {/* Mobile menu icon */}
                    <div
                        className={styles.mobileIcon}
                        onClick={mobileMenuHandler}
                    >
                        <i
                            className={
                                mobileClass
                                    ? 'icon-cross text-lg'
                                    : 'icon-menu1'
                            }
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
