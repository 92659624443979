import Link from 'next/link'
import Image from 'next/image'

const Logo = () => {
    return (
        <div className="relative logo">
            <Image
                src="/logo.svg"
                alt={process.env.NEXT_PUBLIC_APP_NAME}
                layout="responsive"
                width={246}
                height={42}
            />

            <Link href="/">
                <a className="absolute top-0 left-0 right-0 z-40 w-full h-full"></a>
            </Link>
        </div>
    )
}

export default Logo
