import { Formik, Form } from 'formik'

import { useMemo, useState } from 'react'

import TextBox from './base/TextBox'
import Button from './base/Button'

import subscribeValidation from '../validations/subscribe'

const Subscribe = ({ signup }) => {
    const [isSubmitted, setIsSubmitted] = useState(false)

    const initialState = useMemo(
        () => ({
            email: '',
        }),
        []
    )

    const handleSubmit = async ({ email }, { resetForm }) => {
        await fetch('/api/subscribe', {
            body: JSON.stringify({
                email,
                signup,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })

        setIsSubmitted(true)

        resetForm()
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialState}
            validationSchema={subscribeValidation}
        >
            {() => {
                return (
                    <Form noValidate>
                        <div
                            className={`flex w-full max-w-[700px] mx-auto ${
                                signup ? 'flex-col' : ''
                            }`}
                        >
                            <div className="w-full mr-4">
                                <TextBox
                                    id="email"
                                    name="email"
                                    type="email"
                                    disabled={isSubmitted}
                                    placeholder="Enter your email address"
                                />
                            </div>
                            <div className="flex-shrink-0 mt-1.5">
                                <Button
                                    type="submit"
                                    disabled={isSubmitted}
                                    variant="primary"
                                    modification={signup ? 'w-full -mt-2' : ''}
                                    id={
                                        signup
                                            ? 'waitlist_submit'
                                            : 'newsletter_submit'
                                    }
                                    text={
                                        !isSubmitted
                                            ? signup
                                                ? 'Join Waitlist'
                                                : 'Subscribe now'
                                            : 'Thank you.'
                                    }
                                />
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default Subscribe
