import React from 'react'

import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'

import styles from './Navigation.module.css'

import Button from './Button'

import navigationItems from '../../data/NavigationItems'

const Navigation = (props) => {
    const router = useRouter()
    return (
        <div className={`${styles.navigation} ${styles[props.navigationType]}`}>
            <ul>
                {navigationItems.map((nav) => (
                    <li
                        key={nav.id}
                        className={`${
                            nav.footer && !props.isFooter ? styles.hide : ''
                        }`}
                    >
                        {/* if nav Links */}
                        <Link href={nav.route}>
                            <a
                                className={
                                    router.pathname === nav.route
                                        ? styles.active
                                        : ''
                                }
                                onClick={props.hideMobileMenu}
                            >
                                {/* nav label */}
                                {nav.label}

                                {/* nav icon */}
                                {nav.children && (
                                    <i className="icon-right-icon"></i>
                                )}
                            </a>
                        </Link>
                        {nav.hasClickHandler && (
                            <span
                                className="absolute top-0 left-0 right-0 w-full h-full cursor-pointer"
                                onClick={props.clickHandler}
                            ></span>
                        )}
                        {/* =============== Dropdown =============== */}
                        {nav.children && (
                            <div className={styles.subNavigation}>
                                <div className={styles.inner}>
                                    <div>
                                        <ul>
                                            {nav.children.map((child) => (
                                                <li
                                                    key={child.id}
                                                    className="flex"
                                                >
                                                    {/* nav Links */}
                                                    <Link href={child.route}>
                                                        <a
                                                            className={`flex ${
                                                                router.pathname ===
                                                                child.route
                                                                    ? styles.active
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                props.hideMobileMenu
                                                            }
                                                        >
                                                            {/* nav image */}
                                                            <span className="flex-shrink-0 mr-4 w-14">
                                                                <Image
                                                                    src={
                                                                        child.image
                                                                    }
                                                                    alt={
                                                                        child.label
                                                                    }
                                                                    layout="responsive"
                                                                    width={48}
                                                                    height={48}
                                                                />
                                                            </span>
                                                            <span>
                                                                {/* nav label */}
                                                                <h5>
                                                                    {
                                                                        child.label
                                                                    }
                                                                </h5>
                                                                {/* nav description */}
                                                                <p>
                                                                    {
                                                                        child.description
                                                                    }
                                                                </p>
                                                            </span>
                                                        </a>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    {/* Custom AI models */}
                                    <div>
                                        <div
                                            className={
                                                styles.subNavigationFooter
                                            }
                                        >
                                            <div className="flex">
                                                <div>
                                                    <h5 className="mb-2 text-base font-semibold">
                                                        Custom AI Models
                                                    </h5>
                                                    <p className="mb-0 text-sm text-interface-700">
                                                        Need custom built
                                                        Machine Learning models
                                                        and AI solutions?
                                                    </p>
                                                </div>
                                                <div className="ml-auto">
                                                    <Button
                                                        text="Contact Us"
                                                        type="button"
                                                        variant="primary"
                                                        link="/contact"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Navigation
