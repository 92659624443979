// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // Input
    input: {
        base: 'block w-full text-sm rounded-md shadow-none h-11 border-interface-300 focus:shadow-none focus:outline-none ',
        valid: 'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200',
    },
    // Card
    card: {
        base: 'rounded-xl shadow',
    },
    cardBody: {
        base: 'p-6',
    },
    // Button
    button: {
        base: 'btn',
        size: {
            larger: 'px-10 py-4 rounded-lg',
            large: 'px-5 py-3 rounded-lg',
            regular: 'px-4 py-2 rounded-lg text-sm',
            small: 'px-3 py-1 rounded-md text-sm',
        },
        primary: {
            base: 'primary',
            active: 'active:bg-purple-600 hover:bg-purple-700 focus:ring focus:ring-purple-300',
            disabled: 'opacity-50 cursor-not-allowed',
        },
        outline: {
            base: 'text-white bg-gray-500 border border-transparent',
            active: 'active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:ring focus:ring-gray-300',
            disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
        },
        link: {
            base: 'text-gray-600 dark:text-gray-400 focus:outline-none border border-transparent',
            active: 'active:bg-transparent hover:bg-gray-100 focus:ring focus:ring-gray-300 dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10',
            disabled: 'opacity-50 cursor-not-allowed',
        },
    },
    // Alert
    alert: {
        base: 'p-4 px-4 relative rounded-lg leading-5 flex item-center alert mb-4 alert',
        withClose: 'pr-12',
        success: 'success',
        danger: 'bg-red-100 text-red-600 dark:bg-red-600 dark:text-white',
        icon: {
            base: 'w-5 h-5',
            success: 'text-success-500 dark:text-green-300 ',
            danger: 'danger',
        },
    },
    // Modal
    modal: {
        base: 'px-6 py-4 z-50 overflow-hidden rounded-t-lg dark:bg-gray-800 w-[640px] mx-auto my-auto sm:rounded-lg sm:m-4 sm:max-w-[720px] bg-transparent',
    },
    // ModalBody
    modalBody: {
        base: 'mb-3 text-sm text-gray-700 dark:text-gray-400',
    },
    // ModalFooter
    modalFooter: {
        base: 'flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800',
    },
    // ModalHeader
    modalHeader: {
        base: ' mb-2 text-lg font-medium text-gray-900 dark:text-gray-300 bg-white',
    },
}
