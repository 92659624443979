import React, { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import Navigation from './Navigation'
import JoinWaitlist from '../modals/JoinWaitlist'
import styles from './Navigation.module.css'

const Footer = () => {
    // Join Waitlist Modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <div
                className={`relative w-full bg-secondary px-40 pb-2 py-8 ipad:px-4 ${styles.footer}`}
            >
                <div className="container">
                    <div className="flex flex-col items-center">
                        {/* Logo */}
                        <div className="w-[42px] h-[42px] relative">
                            <Image
                                src="/logo-icon-inverse.svg"
                                alt="Logo"
                                layout="responsive"
                                width={42}
                                height={42}
                            />
                            <Link href="/">
                                <a className="absolute top-0 left-0 right-0 z-40 w-full h-full"></a>
                            </Link>
                        </div>
                        {/* Navigation */}
                        <div className={`mb-2 ${styles.footerNavigation}`}>
                            <Navigation
                                clickHandler={openModal}
                                isFooter={true}
                            />
                        </div>
                        {/* Separator */}
                        <div className="separator bg-common-white opacity-[20%]"></div>
                    </div>
                    {/* Footer Bottom Section */}
                    <div className="relative my-8 text-sm font-normal text-interface-200 tablet:text-center">
                        <div>
                            <p>
                                &copy; {new Date().getFullYear()}{' '}
                                {process.env.NEXT_PUBLIC_APP_NAME}. All rights
                                reserved.
                            </p>
                        </div>
                        <div className="absolute top-0 right-0 footer-links tablet:relative tablet:text-center">
                            <ul className="flex gap-8 tablet:flex-row tablet:justify-center ">
                                {/* <li>
                                    <Link href="#">
                                        <a>Site Map</a>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link href="/privacy">
                                        <a>Privacy Policy</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/terms">
                                        <a>Terms & Conditions</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Join Waitlist Modal */}
                <JoinWaitlist
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                />
            </div>
        </>
    )
}

export default Footer
