export const contact = {
    name: {
        required: 'Full name is required',
    },
    email: {
        required: 'Email field is required',
        invalid: 'Please enter a valid email address.',
    },
    phone: {
        required: 'Phone number field is required.',
    },
    company: {
        required: 'Company name is required',
    },
    message: {
        required: 'Message is required',
    },
}
