import React from 'react'
import Script from 'next/script'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress' // nprogress module
import 'nprogress/nprogress.css' // styles of nprogress
React.useLayoutEffect = React.useEffect

// Layout
import MainLayout from '../layouts/Main'
// main css file
import '../styles/icons.css'
import '../styles/fonts.css'
import '../styles/tailwind.css'
// ui framework
// https://windmillui.com/react-ui/installation
import { Windmill } from '@windmill/react-ui'
import myTheme from '../../windMillTheme.js'

// Binding events
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps }) => {
    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout || ((page) => page)
    return getLayout(
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
                />
            </Head>
            {process.env.NEXT_PUBLIC_JITSU_KEY && (
                <Script
                    src="https://t.jitsu.com/s/lib.js"
                    data-key={process.env.NEXT_PUBLIC_JITSU_KEY}
                    data-init-only="false"
                    defer
                />
            )}
            <Windmill theme={myTheme}>
                <MainLayout>
                    <Component {...pageProps} />
                </MainLayout>
            </Windmill>
        </>
    )
}

export default MyApp
